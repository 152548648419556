import { MantineProvider } from '@mantine/core'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import 'whatwg-fetch' //mainly used in sagas, to fetch stuff >.>
import '../node_modules/antd/dist/antd.less'
import * as AWSCognitor from './awsCognitor/awsCognitor'
import config from './cognitoConfig'
import App from './components/App'
import './tailwind_output_index.css'
import ijtemaReducer from './reducers/ijtemaReducer'
import majalisAndOfficesReducer from './reducers/majalisAndOfficesReducer'
import recentReportsReducer from './reducers/recentReportsReducer'
import backendSaga from './sagas/backendSaga'
import * as serviceWorker from './serviceWorker'
import { ModalsProvider } from '@mantine/modals'

// import weekOfYear from 'dayjs/plugin/weekOfYear'; // antd already extends dayjs (antd-dayjs-webpack-plugin)
// dayjs.extend(weekOfYear);

dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekStart: 1, //  // Monday is the first day of the week.
  yearStart: 4, // Used to determine first week of the year -- here the week where 4th of Jan is found is counted as first week
})

AWSCognitor.configureAmplify({
  config,
  api: {
    endpoints: [
      {
        name: 'GetBackendURLs',
        endpoint:
          'https://lambda.' +
          config.cognito.REGION +
          '.amazonaws.com/2015-03-31/functions/arn:aws:lambda:ap-southeast-1:377507561593:function:GetBackendURLs/invocations',
        service: 'lambda',
        region: config.cognito.REGION,
      },
    ],
  },
})

// s2RktrdJhf3SLGAwuRup
const STATE_VERSION = 22
const loadState = () => {
  //fetch from local storage
  try {
    const serialisedState = localStorage.getItem('state')
    if (serialisedState === null) {
      return undefined
    }
    const properState = JSON.parse(serialisedState)
    if (properState.version === undefined || properState.version !== STATE_VERSION) {
      return undefined
    }
    delete properState.version
    return properState
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    state = {
      ...state,
      version: STATE_VERSION,
    }
    const serialisedState = JSON.stringify(state)
    localStorage.setItem('state', serialisedState)
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

const awsSagaMiddleware = createSagaMiddleware()
const backendMiddleware = createSagaMiddleware()

const store = createStore(
  AWSCognitor.enhancedCombineReducers({
    majalisAndOffices: majalisAndOfficesReducer,
    recentReports: recentReportsReducer,
    ijtema: ijtemaReducer,
  }),
  loadState(), // TODO: uncomment when ready to start caching state
  applyMiddleware(awsSagaMiddleware, backendMiddleware)
)

awsSagaMiddleware.run(AWSCognitor.awsCognitorSaga)
backendMiddleware.run(backendSaga)

store.subscribe(() => {
  saveState(store.getState())
})

AWSCognitor.refreshSession(store)

const theme = {
  primaryColor: 'primary',
  colors: {
    primary: ['#777d82', '#61676d', '#4a5158', '#343c43', '#1d262e', '#171e25', '#11171c', '#0c0f12', '#030405'],
  },
  defaultGradient: { from: 'primary', to: 'cyan.4', deg: 120 },
}

ReactDOM.render(
  <Provider store={store}>
    <MantineProvider theme={theme} withNormalizeCSS>
      <ModalsProvider>
        <App />
      </ModalsProvider>
    </MantineProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// console.log('Register service worker');

serviceWorker.register({
  updateViaCache: 'none', // this is new
})

/*
list of classes that needs to be ignored by purge css
ant-picker-header-super-next-btn
ant-picker-header-super-prev-btn
ant-picker-header-next-btn
ant-picker-next-icon
ant-picker-header-prev-btn
ant-picker-prev-icon
*/
