export const LOGIN = 'LOGIN' // user sent login request
export const LOGOUT = 'LOGOUT' // user sent logout request
export const LOGGING_IN = 'LOGGING_IN' // processin login request
export const LOGGED_IN = 'LOGGED_IN' // user logged in
export const LOGGED_OUT = 'LOGGED_OUT' // user logged out
export const LOGIN_ERROR = 'LOGIN_ERROR' // failed to logged in
export const SESSION_EXPIRED = 'SESSION_EXPIRED' // session expired

export const REFRESH_LOGIN = 'REFRESH_LOGIN'

export const CREATE_USER = 'CREATE_USER' // create user -- only admin
export const CREATE_ADMIN = 'CREATE_ADMIN' // -- only admin
export const USER_CREATED = 'USER_CREATED' // -- only admin
export const GET_USERS = 'GET_USERS' // request to get users -- only admin
export const LOAD_USERS = 'LOAD_USERS' // save users in state -- only admin
export const SIGNUP_ERROR = 'SIGNUP_ERROR' // -- only admin
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS' // -- only admin

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
