import { createReducer } from 'redux-create-reducer'
import * as actionTypes from '../actions/actionTypes'

// Note: actual state hierarchy = state => [reducer name, or given name] => whatever is defined below

const initialState = {
  users: [],
  allIndividualNamazReports: {},
  allLocalAmlaNamazReports: {},
  allMajlisNamazReports: {},
  activityReports: {},
  allActivityReports: {},
  ownNamazReports: {},
  ownMuhtamimReports: {},
  ownDoorToDoorReports: {},
  doorToDoorSummary: {},
  allMuhtamimReports: {},
  ownSpiritualReformReports: {},
}

export default createReducer(initialState, {
  LOGGED_OUT() {
    return initialState
  },
  LOAD_USERS(state, action) {
    return {
      ...state,
      users: action.payload.users,
    }
  },
  [actionTypes.GET_SPIRITUAL_REFORM_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      ownSpiritualReformReports: {
        ...state.ownSpiritualReformReports,
        [action.payload.year]: action.payload.result,
      },
    }
  },
  [actionTypes.GET_INDIVIDUAL_NAMAZ_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      allIndividualNamazReports: {
        ...state.allIndividualNamazReports,
        [action.payload.monthYear]: action.payload.result.individualNamazReports,
      },
    }
  },
  [actionTypes.GET_MAJLIS_NAMAZ_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      allLocalAmlaNamazReports: {
        ...state.allLocalAmlaNamazReports,
        [action.payload.monthYear]: action.payload.result.localAmlaNamazReports,
      },
      allMajlisNamazReports: {
        ...state.allMajlisNamazReports,
        [action.payload.monthYear]: action.payload.result.majlisNamazReports,
      },
    }
  },
  [actionTypes.GET_ACTIVITY_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      activityReports: {
        ...state.activityReports,
        [action.payload.monthYear]: action.payload.result,
      },
    }
  },
  [actionTypes.DELETE_ACTIVITY_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      activityReports: {
        ...state.activityReports,
        [action.payload.monthYear]: [
          ...state.activityReports[action.payload.monthYear].slice(0, action.payload.index),
          ...state.activityReports[action.payload.monthYear].slice(action.payload.index + 1),
        ],
      },
    }
  },
  [actionTypes.GET_OWN_NAMAZ_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      ownNamazReports: {
        ...state.ownNamazReports,
        [action.payload.monthYear]: action.payload.result,
      },
    }
  },
  [actionTypes.DELETE_NAMAZ_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      ownNamazReports: {
        ...state.ownNamazReports,
        [action.payload.monthYear]: {
          ...state.ownNamazReports[action.payload.monthYear],
          [action.payload.source]: [
            ...state.ownNamazReports[action.payload.monthYear][action.payload.source].slice(0, action.payload.index),
            ...state.ownNamazReports[action.payload.monthYear][action.payload.source].slice(action.payload.index + 1),
          ],
        },
      },
    }
  },
  [actionTypes.GET_OWN_MUHTAMIM_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      ownMuhtamimReports: {
        ...state.ownMuhtamimReports,
        [action.payload.monthYear]: action.payload.result,
      },
    }
  },
  [actionTypes.DELETE_MUHTAMIM_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      ownMuhtamimReports: {
        ...state.ownMuhtamimReports,
        [action.payload.monthYear]: [
          ...state.ownMuhtamimReports[action.payload.monthYear].slice(0, action.payload.index),
          ...state.ownMuhtamimReports[action.payload.monthYear].slice(action.payload.index + 1),
        ],
      },
    }
  },
  [actionTypes.GET_OWN_DOOR_TO_DOOR_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      ownDoorToDoorReports: {
        ...state.ownDoorToDoorReports,
        [action.payload.monthYear]: action.payload.result,
      },
    }
  },
  [actionTypes.DELETE_DOOR_TO_DOOR_REPORT_SUCCESS](state, action) {
    return {
      ...state,
      ownDoorToDoorReports: {
        ...state.ownDoorToDoorReports,
        [action.payload.monthYear]: [
          ...state.ownDoorToDoorReports[action.payload.monthYear].slice(0, action.payload.index),
          ...state.ownDoorToDoorReports[action.payload.monthYear].slice(action.payload.index + 1),
        ],
      },
    }
  },
  [actionTypes.GET_DOOR_TO_DOOR_SUMMARY_SUCCESS](state, action) {
    return {
      ...state,
      doorToDoorSummary: {
        ...state.doorToDoorSummary,
        [action.payload.majlis]: action.payload.result,
      },
    }
  },
  [actionTypes.GET_ALL_MUHTAMIM_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      allMuhtamimReports: {
        ...state.allMuhtamimReports,
        [action.payload.key]: action.payload.result,
      },
    }
  },
  [actionTypes.GET_ALL_ACTIVITY_REPORTS_SUCCESS](state, action) {
    return {
      ...state,
      allActivityReports: {
        ...state.allActivityReports,
        [action.payload.monthYear]: action.payload.result,
      },
    }
  },
})
